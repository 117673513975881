<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('epayments_report')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('epayments_report')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="$refs.datatable.getRows()"
                              @filterClear="$refs.datatable.filterClear()"
                              :exportExcel="true" @exportExcel="$refs.datatable.excel()">
                <div class="row">
                    <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                        <b-form-group :label="$t('student_number')">
                            <b-input type="number"
                                     min="0"
                                     v-check-min-max-value
                                     v-model="datatable.queryParams.filter.number">
                            </b-input>
                        </b-form-group>
                    </div>

                    <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                        <b-form-group :label="$t('start_date')">
                            <select-date v-model="datatable.queryParams.filter.start_date"/>
                        </b-form-group>
                    </div>

                    <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                        <b-form-group :label="$t('end_date')">
                            <select-date v-model="datatable.queryParams.filter.end_date"/>
                        </b-form-group>
                    </div>
                </div>
            </datatable-filter>
            <datatable ref="datatable" :isLoading="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       v-show="datatable.showTable"
                       :data-function="callService"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

import EPaymentService from "@/services/EPaymentService";
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import SelectDate from "@/components/interactive-fields/SelectDate";

export default {
    components: {
        SelectDate,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable
    },
    data() {
        return {
            createForm: {},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                        hidden: true,
                    },
                    {
                        label: this.toUpperCase('student_number'),
                        field: 'student_number',
                    },
                    {
                        label: this.toUpperCase('name'),
                        field: 'name',
                    },
                    {
                        label: this.toUpperCase('surname'),
                        field: 'surname',
                    },
                    {
                        label: this.toUpperCase('amount'),
                        field: 'amount',
                        formatFn: this.formatNumber
                    },
                    {
                        label: this.toUpperCase('installment'),
                        field: 'installment',
                    },
                    {
                        label: this.toUpperCase('bank'),
                        field: 'bank_code',
                    },
                    {
                        label: this.toUpperCase('credit_card_number'),
                        field: 'credit_card_number',
                    },
                    {
                        label: this.toUpperCase('transaction_reference'),
                        field: 'transaction_reference',
                    },
                    {
                        label: this.toUpperCase('message'),
                        field: 'message',
                    },
                    {
                        label: this.toUpperCase('status'),
                        field: 'status_text',
                    },
                    {
                        label: this.toUpperCase('created_at'),
                        field: 'created_at',
                    },
                    {
                        label: this.toUpperCase('updated_at'),
                        field: 'updated_at',
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                    },
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
        }
    },
    metaInfo() {
        return {
            title: this.$t("epayments_report")
        }
    },
    methods: {
        callService(config) {
            return EPaymentService.report(config)
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.$refs.datatable.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.$refs.datatable.getRows();
        },
    }
}
</script>
